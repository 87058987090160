.se-pre-con {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
/* .se-pre-con span {
  z-index: 9999999;
  display: block;
  position: absolute;
  top: 50%;
  right: 50%;
  border: 6px solid #f3f3f3;
  border-top: 6px solid #ed5f25;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-right: -25px;
  animation: spin 2s linear infinite;
} */
.se-pre-con img {
  position: absolute;
  width: 60px;
  animation-play-state: paused;
  top: 50%;
  right: 50%;
  margin-right: -22px;
  margin-top: 4px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hideLoader {
  -webkit-transition: all 1s linear ease-out-in;
  -moz-transition: all 1s linear ease-out-in;
  -o-transition: all 1s linear ease-out-in;
  transition: all 1s linear ease-out-in;
  top: -100% !important;
  opacity: 0;
  display: none;
}
/* .row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
} */

/********** Add Your Global CSS Here **********/
.btn-fill.refresh-btn {
  position: absolute;
  bottom: 10px;
  right: 40px;
  z-index: 2;
}

body {
  -webkit-overflow-scrolling: touch;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  /* margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004); */

  margin-bottom: 0;
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0px 0px rgb(0 0 0);
  letter-spacing: 0.35px;
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.master-title-dark span {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 900;
  color: #404040;
  margin-top: 10px;
  letter-spacing: 0.75px;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/

/* Popover */

html[dir="rtl"] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */

html[dir="rtl"] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */

html[dir="rtl"] .has-success.has-feedback:after,
html[dir="rtl"] .has-warning.has-feedback:after,
html[dir="rtl"] .has-error.has-feedback:after,
html[dir="rtl"] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir="rtl"] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir="rtl"] .ant-modal-footer {
  text-align: left;
}

html[dir="rtl"] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir="rtl"] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir="rtl"] .ant-btn > .anticon + span,
html[dir="rtl"] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir="rtl"] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir="rtl"]
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir="rtl"]
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */

html[dir="rtl"] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */

html[dir="rtl"] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir="rtl"] .ant-popover-buttons {
  text-align: left;
}

/* Notification */

html[dir="rtl"]
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir="rtl"]
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir="rtl"]
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir="rtl"] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir="rtl"] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */

html[dir="rtl"] .dz-hidden-input {
  display: none;
}

.ant-radio-group.disblocksize{
  margin: 5px 0px;
  display: block !important;
}

.ant-radio-group.disinlinesize > .ant-radio-wrapper{
  display: inline-block;
}


.ant-radio-group.disblocksize > .ant-radio-wrapper{
  display: block;
  margin: 5px 0px;
}

.changepagename{
  line-height: 0;
  margin-bottom: 10px;
  display: flex;
}

.ql-snow .ql-picker-label{
  display: flex;
}

.form-wrap.form-builder .frmb .field-label, .form-wrap.form-builder .frmb .legend{
  display: inline;
    line-height: 31px!important;
}
.form-wrap.form-builder .frmb .field-label > p, .form-wrap.form-builder .frmb .field-label .MsoNormal{
  display: inline;
}

.survey-form .ant-form-item label.normal-label > p,.survey-form .ant-form-item label.normal-label .MsoNormal,
.ant-form-item label.normal-label > p, .ant-form-item label.normal-label .MsoNormal{
  display: inline;
}

.form-builder-dialog h3{
  display: none;
}
.form-builder-dialog{
  overflow-y: hidden!important;
}
.form-builder-dialog .button-wrap .yes.btn {
  margin-left: 10px;
  background: #e03400;
  color: #fff !important;
  border-color: #e03400 !important;
  padding: 0px 25px;
  border: 1px solid #e03400;
  font-weight: normal;
  font-size: 13px;
  height: 30px;
}
.form-builder-dialog .button-wrap .no.btn {
  margin-left: 10px;
  background: #fff;
  color: #e03400 !important;
  padding: 0px 25px;
  border: 1px solid #e03400;
  font-weight: normal;
  font-size: 13px;
  height: 30px;
}

.frmb li.form-field:first-child *[tooltip]:hover::before {
  bottom: -5px !important;
  border-color: transparent transparent #222 transparent;
  border-width: 0px 6px 6px 6px;
}
.frmb li.form-field:first-child *[tooltip]:hover:after{
  bottom: -42px !important;
}
.form-wrap.form-builder .frmb .sortable-options>li:nth-child(1) .remove {
  display: block!important;
  visibility: hidden;
}
.form-wrap.form-builder .frmb .radio-group-field .sortable-options li:nth-child(2) .remove {
  display: block!important;
  visibility: hidden;
}

.form-wrap.form-builder .frmb .sortable-options .option-selected {
  display: none;
}

.form-wrap.form-builder .frmb .master-fields .sortable-options .option-selected {
  display: block;
  width: 20.22px;
}

.form-wrap.form-builder .frmb .master-fields .sortable-options .remove.btn,
.form-wrap.form-builder .frmb .master-fields .field-options .option-actions,
.form-wrap.form-builder .frmb .master-fields [data-attr="value"],
.form-wrap.form-builder .frmb .master-fields [data-attr="optionWeightage"],
.form-wrap.form-builder .frmb .master-fields .form-group.field-options {
  display: none!important;
}

.form-wrap.form-builder .frmb .master-fields [data-attr="label"]{
  width: 100% !important;
  border: none !important;
}

.form-wrap.form-builder .frmb .master-fields input:disabled {
  background-color: #f0f0f0;
  color: #999;
  /* cursor: not-allowed; */
}


/* .form-wrap.form-builder .formbuilder-checkbox-group input[type=checkbox], .form-wrap.form-builder .formbuilder-checkbox-group input[type=radio], .form-wrap.form-builder .formbuilder-radio-group input[type=checkbox], .form-wrap.form-builder .formbuilder-radio-group input[type=radio] {
  margin: 0 4px 0 0;
  display: none;
} */
.normal-label > *{
  display: inline-block;
}

.prev-holder.forcedisplay {
  display: block !important;
}

.editing .prev-holder.forcedisplay {
    background: #f7f7f7;
    padding: 10px;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
}

.form-wrap.form-builder .frmb .formbuilder-radio-group .formbuilder-radio,
.form-wrap.form-builder .frmb .formbuilder-checkbox-group .formbuilder-checkbox{
  display: flex;
  align-items: start;
}

.form-wrap.form-builder .frmb .formbuilder-radio-group .formbuilder-radio input,
.form-wrap.form-builder .frmb .formbuilder-checkbox-group .formbuilder-checkbox input{
  margin-bottom: 10px;
  margin-top: 4px;
}

.survey-form .ant-radio-group label.ant-radio-wrapper span,
.survey-form .ant-checkbox-group label.ant-checkbox-wrapper span{
  white-space: break-spaces;
}

.survey-form.giveresponse .ant-radio-group label.ant-radio-wrapper span,
.survey-form.giveresponse .ant-checkbox-group label.ant-checkbox-wrapper span{
  white-space: normal;
}

.weightegwrappeer > .raisedquery{
  line-height: 20px;
  color: rgba(0, 0, 0, 0.65)!important;
}
.isomorphicSidebar .ant-menu-submenu-inline > .ant-menu-submenu-title:after,
.isomorphicSidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title:after {
  content: "" !important;
}

.menu-headings label {
  color: #9cadbf;
  padding-left: 15px;
  padding-top: 15px;
  font-weight: 500;
  font-size: 16px;
}

.scroll-content .isomorphicSidebar .ant-menu-submenu-arrow {
  right: 0;
}

.ant-layout-has-sider > div {
  height: 100vh;
}

.ant-layout .isomorphicSidebar {
  background: #071821 !important;
  z-index: 105;
}

.isomorphicSidebar .scroll-content .isoDashboardMenu .nav-text {
  color: #fff;
  margin-left: 8px;
}

.config-menu-wrapper {
  border-top: 1px solid #333333;
}

.ant-menu .ant-menu-item.ant-menu-item-active:hover {
  background: #2a3744;
  /* border-left: 4px solid #289bad; */
}

/* .isomorphicSidebar.ant-layout-sider .isoDashboardMenu .ant-menu-item-selected {
  border-left: 5px solid #289bad;
  background-color: #2a3744 !important;
} */

.isomorphicSidebar .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}

.isomorphicSidebar .isoDashboardMenu .ant-menu-item {
  height: 55px;
}

.isoDashboardMenu .ant-menu-item {
  height: 55px;
  border-left: 5px solid transparent;
}

.sidebar-menu-icons {
  max-width: 20px;
}

.user-profile-details {
  padding-top: 5px;
}

.user-side-profile {
  background: #2a3744;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  padding: 20px 10px;
}

.user-profile-pic {
  margin-right: 10px;
}

.user-profile-pic img {
  max-width: 50px;
}

.users-name {
  font-size: 14px;
  font-weight: 600;
}

.profile-buttons {
  margin-top: 10px;
}

.profile-buttons span {
  margin-right: 15px;
}

.sidebar-footer {
  color: #fff;
  text-align: center;
  padding: 10px 0;
  background: #2a3744;
  font-size: 12px;
}
.overflowmain {
  overflow: initial !important;
  background-color: #071821 !important;
}

.overflowmain .scroll-content{
  height: 100%;
}
.powerdeByPreviziDiv{
  color: black;
  position: absolute;
  bottom: 0;
  /* padding-top: 20px; */
  margin-top: 20px;
  font-size: 12px;
  padding: 5px 13px;
  background-color: #fff;
}

.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0px !important;
  padding-left: 12px !important;
}
.eUmHkI
  .isomorphicSidebar
  .ant-menu-submenu-inline
  .ant-menu-inline
  > li:not(.ant-menu-item-group),
.eUmHkI
  .isomorphicSidebar
  .ant-menu-submenu-vertical
  .ant-menu-inline
  > li:not(.ant-menu-item-group),
.eUmHkI
  .isomorphicSidebar
  .ant-menu-submenu-inline
  .ant-menu-submenu-vertical
  > li:not(.ant-menu-item-group),
.eUmHkI
  .isomorphicSidebar
  .ant-menu-submenu-vertical
  .ant-menu-submenu-vertical
  > li:not(.ant-menu-item-group) {
  padding-left: 33px !important;
  padding-right: 0px !important;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: 0px !important;
}

.menu-txt {
  /* width: 220px;
  text-overflow: ellipsis;
  cursor: pointer;

  overflow: hidden;
  white-space: nowrap; */

  width: 130px;
  text-overflow: initial;
  cursor: pointer;
  overflow: visible;
  white-space: break-spaces;
  line-height: 15px;
}

.menu-txt span:hover {
  /* width: 100%; */
  overflow: visible;
  /* white-space: normal; */
  height: auto; /* just added this line */
}

.isomorphicSidebar.ant-layout-sider .isoDashboardMenu .ant-menu-item{
  width: auto;
    text-overflow: initial;
    cursor: pointer;
    overflow: visible;
    white-space: break-spaces;
    line-height: 15px;
}

.gghqHA .isomorphicSidebar.stylingCo {
  top: 80px;
}

.dpo-navbar {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.dpo-navbar ul {
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  height: 60px;
  align-items: center;
}

/* 
.dpo-navbar .nav-pills .nav-link{
  background-color: #f1f3f6;
}
.dpo-navbar .nav-pills .nav-link:hover{
  background-color: #289bad;
  color:#fff;
} */
.isomorphicTopbar.ant-layout-header .dpo-navbar ul li {
  margin-right: 15px;
  padding-top: 5px;
  border: none;
}

.dpo-navbar .nav {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: center;
}
.dpo-navbar .nav .nav-item span {
  letter-spacing: 0.5px;
  font-size: 14px;
}
.dpo-navbar .nav .nav-item,
.dpo-navbar .nav .nav-item .nav-link {
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 0;
  box-shadow: none;
  border-radius: 0 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  border-bottom: 2px solid #e03400 !important;
  color: #e03400;
}

.nav-pills .nav-link:hover {
  color: #e03400;
  border-bottom: 2px solid #e03400 !important;
}

/* Add this style for the active class */
.dpo-navbar .nav-pills .nav-link.active,
.dpo-navbar .nav-pills .show > .nav-link {
  background-color: transparent;
  border-bottom: 2px solid #e03400 !important;
  color: #e03400;
}

/* Add this style for the hover effect (if needed) */
.dpo-navbar .nav-pills .nav-link:hover {
  color: transparent;
  border-bottom: 2px solid #e03400 !important;
}

.dpo-navbar .nav .nav-item .btn-curv {
  border: none;
  padding: 0px 10px;
}

@media (min-width: 1200px) {
  .dpo-navbar .nav .nav-item,
  .dpo-navbar .nav .nav-item .nav-link {
    font-size: 13px;
  }
}

.user-top-profile .isoImgWrapper img {
  border-radius: 50%;
}

.Top-username {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.isomorphicTopbar .isoRight li {
  margin-right: -20px !important;
}

.compnayuser-name {
  cursor: default;
  width: 100%;
  background-color: #e8e8e8;
  padding: 5px 13px 5px;
  color: #000;
}

.user-top-profile label.upload-placeholder {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #e03400;
  font-size: 20px;
  color: #fff;
  line-height: 50px;
  margin: 10px 0;
  cursor: pointer;
}
.prof-image1 {
  height: 66px !important;
}
/* .prof-image2 {
  height: 63px !important;
  bottom: 0px !important;
} */
.dpo-navbar {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

/* Custom Scrollbar */
.dpo-navbar::-webkit-scrollbar {
  height: 8px;
  width: 8px; 
}

.dpo-navbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.dpo-navbar::-webkit-scrollbar-thumb {
  background-color: #f85d2d; 
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.dpo-navbar::-webkit-scrollbar-thumb:hover {
  background-color: #e03400; 
}
.se-pre-con {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease-out;
}
/* .se-pre-con span {
  z-index: 9999999;
  display: block;
  position: absolute;
  top: 50%;
  right: 50%;
  border: 6px solid #f3f3f3;
  border-top: 6px solid #ed5f25;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-right: -25px;
  animation: spin 2s linear infinite;
} */
.se-pre-con img {
  position: absolute;
  width: 60px;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
  top: 50%;
  right: 50%;
  margin-right: -22px;
  margin-top: 4px;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.hideLoader {
  transition: all 1s linear ease-out-in;
  top: -100% !important;
  opacity: 0;
  display: none;
}
/* .row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
} */

/********** Add Your Global CSS Here **********/
.btn-fill.refresh-btn {
  position: absolute;
  bottom: 10px;
  right: 40px;
  z-index: 2;
}

body {
  -webkit-overflow-scrolling: touch;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  /* margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004); */

  margin-bottom: 0;
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0px 0px rgb(0 0 0);
  letter-spacing: 0.35px;
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.master-title-dark span {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 900;
  color: #404040;
  margin-top: 10px;
  letter-spacing: 0.75px;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/

/* Popover */

html[dir="rtl"] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */

html[dir="rtl"] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */

html[dir="rtl"] .has-success.has-feedback:after,
html[dir="rtl"] .has-warning.has-feedback:after,
html[dir="rtl"] .has-error.has-feedback:after,
html[dir="rtl"] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir="rtl"] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir="rtl"] .ant-modal-footer {
  text-align: left;
}

html[dir="rtl"] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir="rtl"] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir="rtl"] .ant-btn > .anticon + span,
html[dir="rtl"] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir="rtl"] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir="rtl"]
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir="rtl"]
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */

html[dir="rtl"] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */

html[dir="rtl"] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir="rtl"] .ant-popover-buttons {
  text-align: left;
}

/* Notification */

html[dir="rtl"]
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir="rtl"]
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir="rtl"]
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir="rtl"] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir="rtl"] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */

html[dir="rtl"] .dz-hidden-input {
  display: none;
}

.ant-radio-group.disblocksize{
  margin: 5px 0px;
  display: block !important;
}

.ant-radio-group.disinlinesize > .ant-radio-wrapper{
  display: inline-block;
}


.ant-radio-group.disblocksize > .ant-radio-wrapper{
  display: block;
  margin: 5px 0px;
}

.changepagename{
  line-height: 0;
  margin-bottom: 10px;
  display: flex;
}

.ql-snow .ql-picker-label{
  display: flex;
}

.form-wrap.form-builder .frmb .field-label, .form-wrap.form-builder .frmb .legend{
  display: inline;
    line-height: 31px!important;
}
.form-wrap.form-builder .frmb .field-label > p, .form-wrap.form-builder .frmb .field-label .MsoNormal{
  display: inline;
}

.survey-form .ant-form-item label.normal-label > p,.survey-form .ant-form-item label.normal-label .MsoNormal,
.ant-form-item label.normal-label > p, .ant-form-item label.normal-label .MsoNormal{
  display: inline;
}

.form-builder-dialog h3{
  display: none;
}
.form-builder-dialog{
  overflow-y: hidden!important;
}
.form-builder-dialog .button-wrap .yes.btn {
  margin-left: 10px;
  background: #e03400;
  color: #fff !important;
  border-color: #e03400 !important;
  padding: 0px 25px;
  border: 1px solid #e03400;
  font-weight: normal;
  font-size: 13px;
  height: 30px;
}
.form-builder-dialog .button-wrap .no.btn {
  margin-left: 10px;
  background: #fff;
  color: #e03400 !important;
  padding: 0px 25px;
  border: 1px solid #e03400;
  font-weight: normal;
  font-size: 13px;
  height: 30px;
}

.frmb li.form-field:first-child *[tooltip]:hover::before {
  bottom: -5px !important;
  border-color: transparent transparent #222 transparent;
  border-width: 0px 6px 6px 6px;
}
.frmb li.form-field:first-child *[tooltip]:hover:after{
  bottom: -42px !important;
}
.form-wrap.form-builder .frmb .sortable-options>li:nth-child(1) .remove {
  display: block!important;
  visibility: hidden;
}
.form-wrap.form-builder .frmb .radio-group-field .sortable-options li:nth-child(2) .remove {
  display: block!important;
  visibility: hidden;
}

.form-wrap.form-builder .frmb .sortable-options .option-selected {
  display: none;
}

.form-wrap.form-builder .frmb .master-fields .sortable-options .option-selected {
  display: block;
  width: 20.22px;
}

.form-wrap.form-builder .frmb .master-fields .sortable-options .remove.btn,
.form-wrap.form-builder .frmb .master-fields .field-options .option-actions,
.form-wrap.form-builder .frmb .master-fields [data-attr="value"],
.form-wrap.form-builder .frmb .master-fields [data-attr="optionWeightage"],
.form-wrap.form-builder .frmb .master-fields .form-group.field-options {
  display: none!important;
}

.form-wrap.form-builder .frmb .master-fields [data-attr="label"]{
  width: 100% !important;
  border: none !important;
}

.form-wrap.form-builder .frmb .master-fields input:disabled {
  background-color: #f0f0f0;
  color: #999;
  /* cursor: not-allowed; */
}


/* .form-wrap.form-builder .formbuilder-checkbox-group input[type=checkbox], .form-wrap.form-builder .formbuilder-checkbox-group input[type=radio], .form-wrap.form-builder .formbuilder-radio-group input[type=checkbox], .form-wrap.form-builder .formbuilder-radio-group input[type=radio] {
  margin: 0 4px 0 0;
  display: none;
} */
.normal-label > *{
  display: inline-block;
}

.prev-holder.forcedisplay {
  display: block !important;
}

.editing .prev-holder.forcedisplay {
    background: #f7f7f7;
    padding: 10px;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
}

.form-wrap.form-builder .frmb .formbuilder-radio-group .formbuilder-radio,
.form-wrap.form-builder .frmb .formbuilder-checkbox-group .formbuilder-checkbox{
  display: flex;
  align-items: start;
}

.form-wrap.form-builder .frmb .formbuilder-radio-group .formbuilder-radio input,
.form-wrap.form-builder .frmb .formbuilder-checkbox-group .formbuilder-checkbox input{
  margin-bottom: 10px;
  margin-top: 4px;
}

.survey-form .ant-radio-group label.ant-radio-wrapper span,
.survey-form .ant-checkbox-group label.ant-checkbox-wrapper span{
  white-space: break-spaces;
}

.survey-form.giveresponse .ant-radio-group label.ant-radio-wrapper span,
.survey-form.giveresponse .ant-checkbox-group label.ant-checkbox-wrapper span{
  white-space: normal;
}

.weightegwrappeer > .raisedquery{
  line-height: 20px;
  color: rgba(0, 0, 0, 0.65)!important;
}

.isomorphicSidebar .ant-menu-submenu-inline > .ant-menu-submenu-title:after,
.isomorphicSidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title:after {
  content: "" !important;
}

.menu-headings label {
  color: #9cadbf;
  padding-left: 15px;
  padding-top: 15px;
  font-weight: 500;
  font-size: 16px;
}

.scroll-content .isomorphicSidebar .ant-menu-submenu-arrow {
  right: 0;
}

.ant-layout-has-sider > div {
  height: 100vh;
}

.ant-layout .isomorphicSidebar {
  background: #071821 !important;
  z-index: 105;
}

.isomorphicSidebar .scroll-content .isoDashboardMenu .nav-text {
  color: #fff;
  margin-left: 8px;
}

.config-menu-wrapper {
  border-top: 1px solid #333333;
}

.ant-menu .ant-menu-item.ant-menu-item-active:hover {
  background: #2a3744;
  /* border-left: 4px solid #289bad; */
}

/* .isomorphicSidebar.ant-layout-sider .isoDashboardMenu .ant-menu-item-selected {
  border-left: 5px solid #289bad;
  background-color: #2a3744 !important;
} */

.isomorphicSidebar .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}

.isomorphicSidebar .isoDashboardMenu .ant-menu-item {
  height: 55px;
}

.isoDashboardMenu .ant-menu-item {
  height: 55px;
  border-left: 5px solid transparent;
}

.sidebar-menu-icons {
  max-width: 20px;
}

.user-profile-details {
  padding-top: 5px;
}

.user-side-profile {
  background: #2a3744;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  padding: 20px 10px;
}

.user-profile-pic {
  margin-right: 10px;
}

.user-profile-pic img {
  max-width: 50px;
}

.users-name {
  font-size: 14px;
  font-weight: 600;
}

.profile-buttons {
  margin-top: 10px;
}

.profile-buttons span {
  margin-right: 15px;
}

.sidebar-footer {
  color: #fff;
  text-align: center;
  padding: 10px 0;
  background: #2a3744;
  font-size: 12px;
}
.overflowmain {
  overflow: initial !important;
  background-color: #071821 !important;
}

.overflowmain .scroll-content{
  height: 100%;
}
.powerdeByPreviziDiv{
  color: black;
  position: absolute;
  bottom: 0;
  /* padding-top: 20px; */
  margin-top: 20px;
  font-size: 12px;
  padding: 5px 13px;
  background-color: #fff;
}

.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0px !important;
  padding-left: 12px !important;
}
.eUmHkI
  .isomorphicSidebar
  .ant-menu-submenu-inline
  .ant-menu-inline
  > li:not(.ant-menu-item-group),
.eUmHkI
  .isomorphicSidebar
  .ant-menu-submenu-vertical
  .ant-menu-inline
  > li:not(.ant-menu-item-group),
.eUmHkI
  .isomorphicSidebar
  .ant-menu-submenu-inline
  .ant-menu-submenu-vertical
  > li:not(.ant-menu-item-group),
.eUmHkI
  .isomorphicSidebar
  .ant-menu-submenu-vertical
  .ant-menu-submenu-vertical
  > li:not(.ant-menu-item-group) {
  padding-left: 33px !important;
  padding-right: 0px !important;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: 0px !important;
}

.menu-txt {
  /* width: 220px;
  text-overflow: ellipsis;
  cursor: pointer;

  overflow: hidden;
  white-space: nowrap; */

  width: 130px;
  text-overflow: initial;
  cursor: pointer;
  overflow: visible;
  white-space: break-spaces;
  line-height: 15px;
}

.menu-txt span:hover {
  /* width: 100%; */
  overflow: visible;
  /* white-space: normal; */
  height: auto; /* just added this line */
}

.isomorphicSidebar.ant-layout-sider .isoDashboardMenu .ant-menu-item{
  width: auto;
    text-overflow: initial;
    cursor: pointer;
    overflow: visible;
    white-space: break-spaces;
    line-height: 15px;
}

.gghqHA .isomorphicSidebar.stylingCo {
  top: 80px;
}

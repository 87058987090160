.dpo-navbar {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.dpo-navbar ul {
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  height: 60px;
  align-items: center;
}

/* 
.dpo-navbar .nav-pills .nav-link{
  background-color: #f1f3f6;
}
.dpo-navbar .nav-pills .nav-link:hover{
  background-color: #289bad;
  color:#fff;
} */
.isomorphicTopbar.ant-layout-header .dpo-navbar ul li {
  margin-right: 15px;
  padding-top: 5px;
  border: none;
}

.dpo-navbar .nav {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: center;
}
.dpo-navbar .nav .nav-item span {
  letter-spacing: 0.5px;
  font-size: 14px;
}
.dpo-navbar .nav .nav-item,
.dpo-navbar .nav .nav-item .nav-link {
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 0;
  box-shadow: none;
  border-radius: 0 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  border-bottom: 2px solid #e03400 !important;
  color: #e03400;
}

.nav-pills .nav-link:hover {
  color: #e03400;
  border-bottom: 2px solid #e03400 !important;
}

/* Add this style for the active class */
.dpo-navbar .nav-pills .nav-link.active,
.dpo-navbar .nav-pills .show > .nav-link {
  background-color: transparent;
  border-bottom: 2px solid #e03400 !important;
  color: #e03400;
}

/* Add this style for the hover effect (if needed) */
.dpo-navbar .nav-pills .nav-link:hover {
  color: transparent;
  border-bottom: 2px solid #e03400 !important;
}

.dpo-navbar .nav .nav-item .btn-curv {
  border: none;
  padding: 0px 10px;
}

@media (min-width: 1200px) {
  .dpo-navbar .nav .nav-item,
  .dpo-navbar .nav .nav-item .nav-link {
    font-size: 13px;
  }
}

.user-top-profile .isoImgWrapper img {
  border-radius: 50%;
}

.Top-username {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.isomorphicTopbar .isoRight li {
  margin-right: -20px !important;
}

.compnayuser-name {
  cursor: default;
  width: 100%;
  background-color: #e8e8e8;
  padding: 5px 13px 5px;
  color: #000;
}

.user-top-profile label.upload-placeholder {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #e03400;
  font-size: 20px;
  color: #fff;
  line-height: 50px;
  margin: 10px 0;
  cursor: pointer;
}
.prof-image1 {
  height: 66px !important;
}
/* .prof-image2 {
  height: 63px !important;
  bottom: 0px !important;
} */
.dpo-navbar {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

/* Custom Scrollbar */
.dpo-navbar::-webkit-scrollbar {
  height: 8px;
  width: 8px; 
}

.dpo-navbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.dpo-navbar::-webkit-scrollbar-thumb {
  background-color: #f85d2d; 
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.dpo-navbar::-webkit-scrollbar-thumb:hover {
  background-color: #e03400; 
}